<template>
	<div
		class="delivery-wr"
		:class="{'basket-type': basketType}"
	>
		<div
			class="delivery"
			:class="{'loading': loading}"
		>
			<transition name="popup-fade">
				<div class="delivery-sorry-state">
					<div class="delivery__header-block">
						<div class="notice">
							<UnavailableAddress
								v-if="notificationType"
								:type="notificationType"
							/>
						</div>
						<div
							class="delivery__header-top"
							:class="{'basket-type': basketType}"
						>

							<div class="delivery__title">Адрес доставки</div>

							<div
								class="delivery__close"
								@click="close"
							>
								<img src="~@/assets/img/close.svg">
							</div>

						</div>
						<SearchMainPopup
							@selectAddress="selectInputAddress"
							:loading="loading"
							:basketType="basketType"
							:isAvailable="notificationType"
						/>
					</div>
					<Map
						class="delivery-map"
						@selectAddress="handleCurrentAddress()"
						:mapid="mapId"
						:isAvailable="notificationType"
					/>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
import SearchMainPopup from "@/components/form/SearchMainPopup.vue";
import Map from "@/components/block/Map.vue";
import {mapGetters} from "vuex";
import UnavailableAddress from "@/components/block/UnavailableAddress";
import search from "@/mixins/search";

export default {
	components: {
		SearchMainPopup,
		Map,
		UnavailableAddress,
	},
	props: ["loading", "inputAddress", "basketType"],
	mixins: [search],
	data()
	{
		return {
			mapId: "popupMap",
			iconPlacemarkAvailable: true,
		};
	},
	computed: {
		...mapGetters({
			notificationType: 'getMapPopupNotificationType',
			currentRestaurantId: 'getRestarauntId',
			deliveryAddress: 'map/getDeliveryAddress',
			deliveryType: 'order/currentDeliveryType'
		}),
	},
	methods:
		{
			close()
			{
				this.$store.dispatch("openPopup", null);
				this.$emit("update:visible", false);
				this.$el.remove();
			},

			async handleCurrentAddress()
			{
				if (this.notificationType === "no-rests")
				{
					this.$store.dispatch("changeInvalidAddress", this.deliveryAddress);
					this.iconPlacemarkAvailable = false;
				} else
				{
					this.$store.dispatch("changeInvalidAddress", false);
					this.iconPlacemarkAvailable = true;
				}
			},

			/**
			 * Срабатывает когда внутри карты меняется адрес
			 */
			async selectMapAddress(address)
			{
				this.notificationType && this.$store.dispatch("mapPopupNotificationTypeAction", false);
			},

			/**
			 * Срабатывает когда пользователь определился с адресом
			 */
			selectInputAddress(address)
			{
				this.$emit("selectAddress", address);
			},
		},
	/**
	 * утснавливает выбранный ранее адрес
	 */
	mounted()
	{
		this.selectMapAddress();
	},
};
</script>
<style
	lang="scss"
	scoped
>
.notice
{
	z-index: 1;
	position: absolute;
	bottom: -90px;
	max-width: 720px;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
}

.delivery
{
	width: 960px;
	border-radius: 24px;
	padding: 32px 40px;
	box-sizing: border-box;
	letter-spacing: .1px;

	&__close
	{
		display: block;
		cursor: pointer;
	}

	&__header
	{
		&-top
		{
			display: flex;
			justify-content: space-between;
		}

		&-block
		{
			position: relative;
		}
	}

	&__filter
	{
		display: flex;
		justify-content: space-between;
		background: $greyBackground;
		padding: 3px;
		border-radius: 12px;
		margin-bottom: 20px;
		position: relative;
		width: 311px;
		overflow: hidden;

		&-bg
		{
			border-radius: 8px;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
			background: #fff;
			width: 49%;
			transition: transform .3s ease-in-out;
			position: absolute;
			height: calc(100% - 6px);
			top: 3px;
			left: 3px;

			#filterMapPickup:checked ~ &
			{
				transform: translateX(100%);
			}
		}

		&-item
		{
			cursor: pointer;
			flex: 1 1 50%;
			color: $textColor;
			font-size: 14px;
			line-height: 24px;
			font-family: $mainFontBoldNew;
			padding: 7px 0;
			font-weight: bold;
			text-align: center;
			position: relative;
			transition: color .3s ease-in-out;
			z-index: 10;

			input:checked + &
			{
				color: $green;
			}
		}
	}

	&.loading
	{
		opacity: 0.5;
	}
}

.delivery-wr
{
	&.basket-type
	{
		&::v-deep
		{
			.delivery__header-top
			{
				display: none;
			}

			.map__controls-geolocation
			{
				margin-top: 0;
				border-radius: 6px;
			}

			.map__controls
			{height: 170px;}
		}
	}
}

.delivery-rest__btn,
.delivery-sorry__btn,
.delivery-sorry__btn
{
	cursor: pointer;
	font-family: $mainFontMediumNew;
	height: 50px;
	font-size: 16px;
}

.delivery__title
{
	font-family: $mainFontBoldNew;
	font-size: 24px;
	font-weight: bold;
	line-height: 120%;
	color: $textColor;
	margin-bottom: 25px;
}

.delivery-search
{
	box-shadow: none !important;
	border: 1px solid #E3E4E4 !important;
}

.delivery-map
{
	width: 100%;
	height: 507px;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
}

.delivery-map__icon
{
	line-height: 0;
	position: absolute;
	top: 140px;
	left: 400px;

	svg
	{
		cursor: pointer;
	}
}

.delivery-rest__row
{
	border: 1px solid #E3E4E4;
	border-radius: 10px;
	box-sizing: border-box;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-left: 40px;
	padding-right: 30px;

	&:not(:last-child)
	{
		margin-bottom: 15px;
	}
}

.delivery-rest__address
{
	font-family: $mainFontMediumNew;
	font-size: 18px;
	line-height: 22px;
	color: $textColor;
}

.delivery-rest__btn
{
	max-width: 200px;
	width: 100%;
	margin-bottom: 11px;
}

.delivery-sorry-state
{
	.delivery__title
	{
		width: 100%;
		text-align: start;
	}
}

.delivery-sorry__bottom
{
	max-width: 587px;
	width: 100%;
	margin: 0 auto 33px;
	display: flex;
}

.delivery-sorry__btn
{
	width: 280px;

	&:not(:last-child)
	{
		margin-right: 25px;
	}
}

@media (max-width: 1200px)
{
	.delivery
	{
		max-width: 100vw;
		padding: 25px;
	}
	.delivery__title
	{
		font-size: 22px;
		width: 70%;
		margin-bottom: 18px;
	}
	.delivery__title
	{
		margin-left: 13px;
	}
	.delivery-search
	{
		margin-bottom: 34px;
		margin-right: 17px;
	}
	.delivery-map
	{
		height: 507px;
		padding: 0;
	}
	.delivery-rest-state
	{
		padding-bottom: 28px;

		.delivery__title
		{
			margin-bottom: 22px;
		}
	}
	.delivery-rest-rows
	{
		padding: 0 15px;
	}
	.delivery-rest__row
	{
		height: unset;
		flex-direction: column;
		padding: 14px 15px 15px 13px;

		&:not(:last-child)
		{
			margin-bottom: 9px;
		}
	}
	.delivery-rest__title
	{
		font-size: 16px;
		padding-bottom: 0;
	}
	.delivery-rest__address
	{
		font-size: 14px;
		line-height: 19px;
	}
	.delivery-rest__btn
	{
		max-width: 260px;
		margin-bottom: 0;
		height: 41px;
		font-size: 14px;
		color: #fff;
		background-color: $red;
		border-color: $red;

		&:hover
		{
			background-color: #FF6A3A;
			border-color: #FF6A3A;
		}
	}
	.delivery-rest__descr
	{
		margin-bottom: 9px;
	}
}

@media (max-width: 990px)
{
	.delivery
	{
		padding: 25px;
		width: 100%;

		& .search
		{
			&-wr
			{
				flex-direction: row;
			}

			&__btn
			{
				max-width: 197px;
				padding: 18px 0;
				width: 100%;
				text-align: center;
				height: 56px;
			}

			&-label
			{
				margin-right: 16px;
			}
		}

		&-sorry
		{
			&__btn
			{
				width: 100%;

				&:not(:last-child)
				{
					margin-bottom: 25px;
				}
			}
		}
	}
}

@media (max-width: 567px)
{
	.delivery-wr
	{
		top: 0;
		left: 0;
		position: fixed;
		overflow: hidden;
		overflow-y: auto;
		height: calc(var(--pseudo-vh) * 100);
		width: 100vw;
		max-width: 100vw;
		touch-action: none;
		-webkit-overflow-scrolling: touch;

		&.basket-type
		{
			display: block;
			width: 100%;
			height: 300px;
			position: static;

			.delivery
			{
				&__header-block
				{display: none;}

				&-map
				{
					position: static;
				}
			}
		}
	}
	.delivery
	{
		padding: 0;
		height: 100%;

		&-search
		{
			margin-bottom: 16px;
		}

		&__title
		{
			font-size: 20px;
			line-height: 32px;
			margin-bottom: 16px;
			margin-left: 0;
		}

		& .search
		{
			&-wr
			{
				flex-direction: column;
			}

			&__btn
			{
				max-width: 100%;
			}

			&-label
			{
				margin-right: 0;
			}
		}

		.delivery-sorry-state
		{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
		}

		&__header-block
		{
			order: 2;
			padding: 20px 32px;
			background-color: #fff;
			width: 100%;
			height: auto;
			display: flex;
			flex-direction: column;
			z-index: 3;
			bottom: 0;
			position: fixed;
			border-radius: 24px 24px 0px 0px;
			box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);

			.notice
			{
				position: fixed;
				top: 0;
				width: 100vw;
				bottom: unset;
			}

		}

		&-map
		{
			order: 1;
			height: calc(100% - 170px);
			border-radius: 0;
			padding: 0;
			position: fixed;


			& .map
			{
				max-height: 100%;
				min-height: 0;
				height: 100%;
			}
		}
	}
}

@media (max-width: 374px)
{
	.delivery
	{

		&-map
		{
			height: 100%;
			flex-grow: 1;
			position: relative;
		}

		.search
		{

			&.search__map_address-input
			{
				font-size: 14px;
				height: 20px;
			}

			&__btn
			{
				font-size: 14px;
				line-height: 20px;
				padding: 14px 0;
				height: 48px;
			}
		}

		&__title
		{
			font-size: 18px;
			line-height: 28px;
			margin-bottom: 8px;
		}

		&__header-block
		{
			padding: 15px 24px;
			min-height: 170px;
			height: 170px;
			position: relative;

			.notice
			{
				position: fixed;
				top: 0;
				width: 100vw;
				bottom: unset;
			}
		}
	}
}
</style>
